@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-carousel {
    height: 285px;
    border-radius: $generic-border-radius;

    @media screen and (max-width: $breakpoint-lg-min) {
        height: 285px;
    }

    @media screen and (max-width: 1439px) {
        height: 270px;
    }

    @media screen and (max-width: 1279px) {
        height: 201px;
    }

    @media screen and (max-width: 960px) {
        height: 170px;
    }
}

.q-carousel :deep(.q-carousel__slides-container) {
    .q-carousel__slide {
        background-repeat: no-repeat;
        padding: 0;
        overflow: hidden;
    }
}

.q-carousel :deep(.q-carousel__arrow) {
    .q-btn {
        color: rgba(0, 0, 0, 0.4);
        background: rgba(255, 255, 255, 0.3);
    }
}

.badge {
    position: absolute;
    bottom: 70%;
    width: 100px;
    height: 50px;
    background: $negative;
    border-radius: 26px 0 0 26px;
    right: 0;
}

.adv-badge {
    position: absolute;
    right: 8px;
    top: 8px;
    background: $secondary;
    opacity: 0.7;
    width: 88px;
    padding: 4px 0;
    border-radius: 16px;
}

.adv-card {
    padding: 16px;
    top: 42px;
    right: 8px;
    position: absolute;
    opacity: 1;
}
